/* You can add global styles to this file, and also import other style files */

:root {
  --color-primary: #004080;
  --color-primary-contrast: #63b0fc;
  --color-secondary: #f1f4fb;
  --color-tertiary: #ededfd;
  --color-toptable: #26598b;
  --color-toptable-contrast: #e4e6e7;
  --color-text: #252525;
  --color-red: #800f00;
  --wroot: 64px;
  //COLORS FOR LEGEND
  --legend-green: #20c020;
  --legend-yellow: #c5d306;
  --legend-orange: #dba612;
  --legend-red: #ee3847;
  --legend-violet: #e41fd3;
  --inactive: #aaaaaa;
}

@font-face {
  font-family: Poppins;
  src: url("/assets/fonts/Poppins-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Poppins;
  src: url("/assets/fonts/Poppins-Black.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url("/assets/fonts/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: url("/assets/fonts/Poppins-Light.ttf");
  font-weight: lighter;
}

html,
body {
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  background-color: var(--color-tertiary);
}

#main {
  margin-left: var(--wroot);
  width: calc(100vw - var(--wroot));
  transition: width 0.8s;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  background-color: var(--color-tertiary);
  .header {
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 12px;
    margin-right: 12px;
    padding-bottom: 4px;
    border-bottom: 1px solid #bbb9b9;
    color: var(--color-primary);
    font-weight: bold;
    font-size: 18px;
    user-select: none;
    .title {
      width: 100%;
      text-align: center;
    }
    .menuicon {
      cursor: pointer;
    }
    .menuico:active {
      transform: scale(0.95);
    }
    .option {
      width: 150px;
      font-size: 12px;
      font-weight: normal;
    }
  }
  .container {
    padding: 4px;
  }
}

#modal {
  padding: 8px;
  font-size: 12px;
  margin-bottom: 20px;
  .headermodal {
    font-weight: bold;
    font-size: 14px;
    padding: 12px;
    border-bottom: 1px solid #ececec;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    .titlemodal {
      width: 100%;
      text-align: center;
    }
    .iconmodal {
      width: 20px;
      cursor: pointer;
    }
  }
  .bodymodal {
    padding: 12px;
    overflow: auto;
    .formdata {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

.table {
  @media only screen and (min-width: 1500px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 2500px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 3500px) {
    font-size: 16px;
  }
  .c {
    text-align: center;
    justify-content: center;
  }
  .toptable {
    color: var(--color-tertiary);
    background-color: var(--color-primary);
    .label {
      display: flex;
      align-items: center;
      padding: 4px;
    }
  }
  .subtoptable {
    color: var(--color-primary);
    background-color: #e6e6e6;
    .label {
      display: flex;
      align-items: center;
      padding: 4px;
    }
  }
  .bodytable {
    color: var(--color-text);
    .label {
      display: flex;
      align-items: center;
      padding: 4px;
    }
  }
  .bodytable:nth-child(even) {
    background-color: var(--color-secondary);
  }
  .bodytable:nth-child(odd) {
    background-color: #fefeff;
  }
  .blinker:nth-child(even) {
    animation: blinker1 3s infinite;
  }
  .blinker:nth-child(odd) {
    animation: blinker2 3s infinite;
  }
}

div[table="true"] {
  display: grid;
  grid-template-columns: var(--cl);
  grid-template-rows: var(--rw);
}

.inputdiv {
  background-color: white;
  padding: 1px;
  padding-top: 8px;
  position: relative;
  overflow: hidden;
  margin: 2px;
  input {
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    background-color: white;
    border: 1px solid #5c5b5b;
    border-radius: 3px;
    font-size: 12px;
    padding: 8px;
    outline: none;
  }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(64, 83, 255, 0.54);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0a14a3;
}

.mat-dialog-container {
  padding: 0 !important;
  overflow: hidden !important;
}

.mat-tooltip {
  color: #fff;
  background-color: var(--color-primary);
  font-size: 12px;
  border-radius: 8px !important;
  box-shadow: 2px 2px 2px 1px rgba(10, 48, 0, 0.548) !important;
}

@keyframes blinker1 {
  0% {
    background-color: var(--color-tertiary);
  }
}

@keyframes blinker2 {
  100% {
    background-color: var(--color-tertiary);
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #bcd4da;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 10px;
}
